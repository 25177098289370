import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: JSON.parse(localStorage.getItem('user')) || {},
    currentRole: localStorage.getItem('currentRole') || 'admin' 
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) =>{
            state.user = action.payload;  
            localStorage.setItem('user', JSON.stringify(state.user));
        } ,
        
        setRole: (state, action) =>{
            state.currentRole = action.payload;  
            localStorage.setItem('currentRole', action.payload);
        }  
    }
})

export const {setUser, setRole} = userSlice.actions;
export default userSlice.reducer;