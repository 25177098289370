import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditWithdrawal = () => {

    const params = useParams();
    const withdrawal = useSelector((state) => state.history.current_withdrawal);
    const addresses = useSelector((state) => state.coins.addresses);

    const initialLogin = {
        amount : withdrawal.amount	,
        source : withdrawal.source	,
        status : withdrawal.status,
        address : withdrawal.address	,
        coin_name : withdrawal.coin_name	,

    }
    
    
    const [error, setErros] = useState('');
    const [withdrawalForm, setwithdrawalForm] = useState(initialLogin);

    const onChange = (e) =>
    setwithdrawalForm({ ...withdrawalForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postwithdrawal } = useMutation(

        async () => {
          return await apiClient.post(`/api/edit-withdrawal/${params.id}`, {
            amount : withdrawalForm.amount,	
            source : withdrawalForm.source,	
            status : withdrawalForm.status,	
            address : withdrawalForm.address,	
            coin_name : withdrawalForm.coin_name,	

          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postwithdrawal();
      } 


  return (
    <div style={{marginTop: '70px'}}>
        {withdrawal !== null && 

        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        EDIT withdrawal
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to edit withdrawal
                    </Typography>
                        
                        
                      <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                          <InputLabel htmlFor="source">Select Payment Source</InputLabel>
                          {(error !== '' && error.address) ?
                          <Select
                          error
                          onChange={onChange}
                          labelId="source"
                          id="source"
                          defaultValue={withdrawal.source}
                          name="source"
                          label={`Select Payment Source`}
                          helperText={error.address}
                          >
                          <MenuItem selected value=''>
                          Select Gateway
                          </MenuItem> 

                          <MenuItem selected value="Earning">
                            Account Balance 
                        </MenuItem>

                        <MenuItem selected value="Referral">
                            Referral Wallet
                        </MenuItem>            
                          
                      </Select>
                          :
                          <Select
                              onChange={onChange}
                              labelId="source"
                              name="source"
                              id="source"
                              defaultValue={withdrawal.source}
                              label={`Select Payment Source`}
                              >
                              <MenuItem selected value=''>
                              Select Gateway
                              </MenuItem> 

                            <MenuItem selected value="Earning">
                                Account Balance 
                            </MenuItem>

                            <MenuItem selected value="Referral">
                                Referral Wallet
                            </MenuItem> 
                          </Select>
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.address}
                          </Typography>
                      </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="amount">Amount</InputLabel>
                            {(error !== '' && error.amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="amount"
                            defaultValue={withdrawal.amount}
                            type={'number'}
                            name="amount"
                            label="Amount"
                            helperText={error.amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="amount"
                            defaultValue={withdrawal.amount}
                            type={'number'}
                            name="amount"
                            label="Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount}
                            </Typography>
                        </FormControl>


                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="earning">Earning</InputLabel>
                            {(error !== '' && error.earning) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            defaultValue={withdrawal.earning}
                            id="earning"
                            type={'number'}
                            name="earning"
                            label="Earning"
                            helperText={error.earning}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="earning"
                            type={'number'}
                            defaultValue={withdrawal.earning}
                            name="earning"
                            label="Earning"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.earning}
                            </Typography>
                            
                        </FormControl> */}

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">address</InputLabel>
                            {(error !== '' && error.address) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="address"
                            defaultValue={withdrawal.address}
                            type={'text'}
                            name="address"
                            label="address"
                            helperText={error.address}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="address"
                            defaultValue={withdrawal.address}
                            type={'text'}
                            name="address"
                            label="address"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.address}
                            </Typography>
                            
                        </FormControl>


                        {addresses.length > 0 &&
                            <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
                                <InputLabel htmlFor="coin_name">Select Coin</InputLabel>
                                {(error !== '' && error.coin_name) ?
                                    <Select
                                        error
                                        onChange={onChange}
                                        labelId="coin_name"
                                        id="coin_name"
                                        name="coin_name"
                                        defaultValue={withdrawal.coin_name}
                                        label={`Select Coin`}
                                        helperText={error.coin_name}
                                        >
                                        <MenuItem selected value=''>
                                            Select Coin
                                        </MenuItem> 
                                        {addresses.map(coin =>(
                                            <MenuItem key={coin.id} value={coin.coin_name}>{coin.coin_name}</MenuItem>
                                        ))}             
                                        
                                    </Select>
                                :
                                    <Select
                                    onChange={onChange}
                                        labelId="coin_name"
                                        name="coin_name"
                                        defaultValue={withdrawal.coin_name}
                                        id="coin_name"
                                        label={`Select Coin`}
                                        >
                                        <MenuItem selected value="">
                                            Select Coin
                                        </MenuItem>              
                                        {addresses.map(coin =>(
                                        <MenuItem key={coin.id} value={coin.coin_name}>{coin.coin_name}</MenuItem>
                                        ))} 
                                    </Select>
                                }
                                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                                {error !== '' && error.coin_name}
                                </Typography>
                            </FormControl>
                        }

                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">address</InputLabel>
                            {(error !== '' && error.address) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="address"
                            name="address"
                            defaultValue={withdrawal.address}
                            type={'text'}
                            label="address"
                            helperText={error.address}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="address"
                            defaultValue={withdrawal.address}
                            name="address"
                            type={'text'}
                            label="address"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.address}
                            </Typography>
                        </FormControl> */}

<FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={onChange}
                    defaultValue={withdrawal.status}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="declined">Declined</MenuItem>
                </Select>
            </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Updating...' : 'Update withdrawal'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </div>
  )
}

export default EditWithdrawal
