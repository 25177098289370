import * as React from 'react';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import ListItemButton from '@mui/material/ListItemButton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletButton from '../Wallet/WalletButton';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { CardActions } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReactApexChart from 'react-apexcharts';
import Button from '@mui/material/Button';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function AdminAnalysis() {
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    
    const todaysProfit = useSelector((state) => state.unitsAnalysis.todaysProfit);
    const amountSoldToday = useSelector((state) => state.unitsAnalysis.amountSoldToday);
    const todaysFundings = useSelector((state) => state.unitsAnalysis.todaysFundings);
    const todaysExpenses = useSelector((state) => state.unitsAnalysis.todaysExpenses);
    const totalSales = useSelector((state) => state.unitsAnalysis.totalSales);
    const totalFundings = useSelector((state) => state.unitsAnalysis.totalFundings);
    const totalUsers = useSelector((state) => state.unitsAnalysis.totalUsers);
    const totalExpenses = useSelector((state) => state.unitsAnalysis.totalExpenses);

    const salesAnalysis = useSelector((state) => state.chartsAnalysis.salesAnalysis);
    const fundingsAnalysis = useSelector((state) => state.chartsAnalysis.fundingsAnalysis);
    // const profitAnalysis = useSelector((state) => state.chartsAnalysis.profitAnalysis);
    const expensesAnalysis = useSelector((state) => state.chartsAnalysis.expensesAnalysis);
    
    let salesData = [];
    let fundingsData = [];
    // let profitsData = [];
    let expenseData = [];

    salesAnalysis.forEach(element => {
        salesData.push(element.amount);
    });

    fundingsAnalysis.forEach(element => {
        fundingsData.push(element.amount);
    });

    // profitAnalysis.forEach(element => {
    //     profitsData.push(element.amount);
    // });

    expensesAnalysis.forEach(element => {
        expenseData.push(element.amount);
    });


    const series =  [{
        name: 'Total Investments',
        data: salesData
      },
      {
        name: 'Total Fundings',
        data: fundingsData
      }
      ,
      {
        name: 'Total Withdrawals',
        data: expenseData
      }
    ];

    const options = {
        chart: {
            foreColor: '#939c92',
          type: 'bar',
          height: 350,
        },
        
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        tooltip: {
            theme: 'dark'
          },
        xaxis: {
            theme: 'dark',
          categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          title: {
            text: 'USD'
          }
        },
        fill: {
          opacity: 1
        },
        
      };


      const [open, setOpen] = React.useState(false);
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

    const [expenseAmount, setExpenseAmount] = useState(0);
    const [reason, setReason] = useState('');

      const { isLoading: isSpending, mutate: postExpenses } = useMutation(
        async () => {
          return await apiClient.post(`/api/store-expenses`, {
            amount: expenseAmount,
            reason: reason,
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                
              }            
          },
          onError: (err) => {
                let errorMessage = err.response?.data || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
          },
        }
      );

   return (
    <div style={{marginTop: '70px'}}>

    <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>        
    {/* <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#0be930'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#0be930'}} primary={formatPrice(todaysProfit)} secondary={`Today's Profit`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>   */}

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#fa8d0d'}}/>
                                </ListItemIcon>
                                <ListItemText sx={{color: '#fa8d0d'}} primary={formatPrice(amountSoldToday)} secondary={`Pending Investment`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                           <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#da0930'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#da0930'}} primary={formatPrice(todaysExpenses)} secondary={`Pending Withdrawals`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>

                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#06b13e'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#06b13e'}} primary={formatPrice(todaysFundings)} secondary={`Pending Funding`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#06b13e'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#06b13e'}} primary={formatPrice(totalSales)} secondary={`Total Investments`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#06afad'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#06afad'}} primary={formatPrice(totalFundings)} secondary={`Total Funding`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                           <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon sx={{color: '#da0930'}} />
                                </ListItemIcon>
                                <ListItemText sx={{color: '#da0930'}} primary={formatPrice(totalExpenses)} secondary={`Total Withdrawals`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>

                    {/* <CardActions sx={{justifyContent: 'center'}}>
                    <Button onClick={handleClickOpen} variant="outlined" size='large' color="primary">
                        {isSpending? 'spending ...' : 'Make Expenses'}
                    </Button>
                    </CardActions> */}

                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <PeopleAltIcon />
                                </ListItemIcon>
                                <ListItemText primary={totalUsers} secondary={`Total Users`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>             

            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <ReactApexChart options={options}  series={series} type="bar" height={350} />
                    </CardContent>
                </Card>
            </Grid> 
    </Grid>


    
    </div>
  );
}