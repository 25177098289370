import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { useMutation } from "react-query";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch } from 'react-redux';
import { setCurrentInvestments } from '../../request/Analysis/hitorySlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListInvestments() {
  const dispatch = useDispatch();
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatPrice = (amount, currency)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const [investments, setInvestments] = useState(null);
    const { isLoading: isLoadinginvestments, refetch: getinvestments } = useQuery(
        "list-investments",
        async () => {
          return await apiClient.get("/api/list-investments");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setInvestments(res.data.investments);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getinvestments() 
        if (isLoadinginvestments) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

      const [open2, setOpen2] = React.useState(false);
      const [investmentId, setInvestmentId] = useState('');
      const [investmentStatus, setInvestmentStatus] = useState('');

      const handleClose2 = () => {
        setOpen2(false);
      };
      const handleToggle2 = () => {
        setOpen2(!open2);
      };


      const { isLoading: isUpdating, mutate: updateInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/update-investment`, {
            investment_id: investmentId,
            investment_status: investmentStatus,
          });
          
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
      
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                setTimeout(() => {
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
                }, 1000);
      
                const event2 = new Event('processed');
                window.dispatchEvent(event2);                
                
              }            
          },
          onError: (err) => {
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event4 = new Event('processed');
            window.dispatchEvent(event4);
            
          },
        }
      );


  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadinginvestments ? 'Loading...' : 'List of investments'}
      </Typography>
    
    <Dialog
        
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"UPDATE STATUS"}</DialogTitle>
        <DialogContent>
          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={(e) => setInvestmentStatus(e.target.value)}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="declined">Declined</MenuItem>
                </Select>
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>updateInvestment()} color='error'>{isUpdating? 'Updating...': 'Update Status'}</Button>
        </DialogActions>
      </Dialog>


      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
            <Tab label="Pending Investments" {...a11yProps(0)} />
            <Tab label="Approved Investments" {...a11yProps(1)} />
            <Tab label="Completed Investments" {...a11yProps(2)} />
          </Tabs>
        </Box>
        
        <CustomTabPanel value={value} index={0}>
        <TableContainer component={Paper}>
        
        {investments !== null &&
        <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          
          <TableCell>User Name</TableCell>
            <TableCell align="right">User Email</TableCell>
            <TableCell align="right">Amount Invested</TableCell>
            <TableCell align="right">Investment Plan</TableCell>
            <TableCell align="right">Investment Duration</TableCell>
            
            <TableCell align="right">Amount Earned</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Update Status</TableCell>
            <TableCell align="right">View/Edit</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments.map((investment) => (
            investment.status === 'pending' &&
            <TableRow
              key={investment.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {investment.user.name}
              </TableCell>
              <TableCell align="right">{investment.user.email}</TableCell>
              <TableCell align="right">{formatPrice(investment.amount_invested, investment.user.currency)}</TableCell>
              <TableCell align="right">{investment.plan.name}</TableCell>
              <TableCell align="right">{investment.plan.duration}</TableCell>
              
              <TableCell align="right">{investment.earning}</TableCell>
              <TableCell align="right">{investment.status}</TableCell>
              <TableCell align="right"><Button
              onClick={() =>{handleToggle2(); setInvestmentId(investment.id);}} 
               startIcon={<EditIcon />}>Update Status</Button></TableCell>

              <TableCell align="right"><Button onClick={ dispatch(setCurrentInvestments(investment))} component={RouterLink} to={`/edit-investment/${investment.id}`} startIcon={<EditIcon />}>View/Edit</Button></TableCell>

              <TableCell align="right">{getDate(investment.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        }
        
      </TableContainer>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
        <TableContainer component={Paper}>
        
        {investments !== null &&
        <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          
          <TableCell>User Name</TableCell>
            <TableCell align="right">User Email</TableCell>
            <TableCell align="right">Amount Invested</TableCell>
            <TableCell align="right">Investment Plan</TableCell>
            <TableCell align="right">Investment Duration</TableCell>
            
            <TableCell align="right">Amount Earned</TableCell>
            <TableCell align="right">Update Status</TableCell>
            <TableCell align="right">View/Edit</TableCell>
            <TableCell align="right">View</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments.map((investment) => (
            investment.status === 'approved' &&
            <TableRow
              key={investment.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {investment.user.name}
              </TableCell>
              <TableCell align="right">{investment.user.email}</TableCell>
              <TableCell align="right">{formatPrice(investment.amount_invested, investment.user.currency)}</TableCell>
              <TableCell align="right">{investment.plan.name}</TableCell>
              <TableCell align="right">{investment.plan.duration}</TableCell>
              
              <TableCell align="right">{investment.earning}</TableCell>
              <TableCell align="right">{investment.status}</TableCell>
              <TableCell align="right"><Button
              onClick={() =>{handleToggle2(); setInvestmentId(investment.id);}} 
               startIcon={<EditIcon />}>Update Status</Button></TableCell>
              <TableCell align="right"><Button onClick={ dispatch(setCurrentInvestments(investment))} component={RouterLink} to={`/edit-investment/${investment.id}`} startIcon={<EditIcon />}>View/Edit</Button></TableCell>

              <TableCell align="right">{getDate(investment.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        }
        
      </TableContainer>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
        <TableContainer component={Paper}>
        
        {investments !== null &&
        <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          
          <TableCell>User Name</TableCell>
            <TableCell align="right">User Email</TableCell>
            <TableCell align="right">Amount Invested</TableCell>
            <TableCell align="right">Investment Plan</TableCell>
            <TableCell align="right">Investment Duration</TableCell>
            
            <TableCell align="right">Amount Earned</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Update Status</TableCell>
            <TableCell align="right">View/Edit</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments.map((investment) => (
            investment.status === 'completed' &&
            <TableRow
              key={investment.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              {investment.user.name}
              </TableCell>
              <TableCell align="right">{investment.user.email}</TableCell>
              <TableCell align="right">{formatPrice(investment.amount_invested, investment.user.currency)}</TableCell>
              <TableCell align="right">{investment.plan.name}</TableCell>
              <TableCell align="right">{investment.plan.duration}</TableCell>
              
              <TableCell align="right">{investment.earning}</TableCell>
              <TableCell align="right">{investment.status}</TableCell>
              <TableCell align="right"><Button
              onClick={() =>{handleToggle2(); setInvestmentId(investment.id);}} 
               startIcon={<EditIcon />}>Update Status</Button></TableCell>
              <TableCell align="right"><Button onClick={ dispatch(setCurrentInvestments(investment))} component={RouterLink} to={`/edit-investment/${investment.id}`} startIcon={<EditIcon />}>View/Edit</Button></TableCell>

              <TableCell align="right">{getDate(investment.created_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        }
        
      </TableContainer>
        </CustomTabPanel>
      </Box>
      </Container>
    </div>
  );
}